<template>
    <div v-loading.fullscreen.lock="loading" class="bg-white w-screen h-screen">
        <div class="flex items-center h-full relative">
            <div
                class="w-[90%] tablet:w-fit absolute bottom-2 -translate-x-1/2 left-1/2"
            >
                <p
                    class="text-left tablet:text-center font-semibold text-base text-brand-atosa"
                >
                    {{ $t('page.login.join_course') }}
                    <a href="https://www.quangcaoshopee.com/" target="_blank">
                        <span class="underline">
                            {{ $t('page.login.here') }}
                        </span>
                    </a>
                </p>
                <p
                    class="text-left tablet:text-center font-semibold text-base text-brand-atosa"
                >
                    {{ $t('page.login.learn_more') }}
                    <a :href="s__website" target="_blank">
                        <span class="underline">
                            {{ $t('page.login.here') }}</span
                        >
                    </a>
                </p>
            </div>

            <div class="absolute top-1/2 -translate-y-1/2 left-4 text-bg w-fit">
                <p class="uppercase font-semibold text-[7rem]">ataME</p>
                <p
                    class="text-lg text-border uppercase font-semibold break-words text-center"
                >
                    {{ $t('page.login.ads_management_toolkit') }}
                </p>
            </div>

            <div
                class="w-[60%] z-10 bg-transparent util-flex-center flex-col z-10"
            >
                <img
                    class="object-cover"
                    :src="require('@/assets/images/bg_tiktok_ads.png')"
                    alt=""
                />

                <el-carousel
                    class="w-full mx-auto"
                    :interval="2000"
                    arrow="never"
                    indicator-position="none"
                >
                    <el-carousel-item
                        v-for="item in p__seo_keywords"
                        :key="item"
                    >
                        <div class="w-full">
                            <p
                                class="text-lg text-border uppercase font-semibold break-words text-center"
                            >
                                {{ item }}
                            </p>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>

            <div class="flex-1 z-10">
                <el-form
                    ref="rule_form"
                    :rules="rules"
                    :model="rule_form"
                    label-position="left"
                    class="w-screen tablet:w-[480px] bg-white border-border border-[1px] shadow-lg py-6 px-4 tablet:py-12 tablet:px-8 tablet:rounded-xl"
                >
                    <div class="util-flex-center">
                        <img
                            class="w-11 h-11"
                            :src="require('@/assets/images/logo_brand.png')"
                            alt=""
                        />
                        <p
                            class="font-semibold text-brand-atosa uppercase text-3xl ml-3"
                        >
                            ATAME
                        </p>
                    </div>

                    <p
                        class="text-center text-brand-atosa font-semibold text-xl uppercase my-6"
                    >
                        {{ $t('page.login.ads_management_toolkit') }}
                    </p>

                    <el-form-item prop="email">
                        <el-input
                            ref="email"
                            v-model="rule_form.email"
                            :placeholder="$t('page.login.placeholder.username')"
                            name="email"
                            type="text"
                            tabindex="1"
                            auto-complete="on"
                            clearable
                        />
                    </el-form-item>

                    <el-form-item prop="password" class="mt-6">
                        <el-input
                            ref="password"
                            v-model="rule_form.password"
                            :type="password_type"
                            :placeholder="$t('page.login.placeholder.password')"
                            name="password"
                            tabindex="2"
                            auto-complete="off"
                            show-password
                            clearable
                            @keyup.enter.native="submitForm()"
                        />
                    </el-form-item>

                    <router-link to="/forget-password">
                        <p
                            class="text-right mb-1 mt-3 text-brand-atosa text-sm font-medium"
                        >
                            {{ $t('page.login.forgot_password') }}
                        </p>
                    </router-link>

                    <!-- <a
                        :href="`https://app.atosa.asia/forgot-password?redirect_url=${redirect_url}`"
                        class="text-sm font-medium"
                    >
                        <p class="text-right mb-1 mt-3 text-brand-atosa">
                            {{ $t('page.login.forgot_password') }}
                        </p>
                    </a> -->

                    <el-button
                        :loading="loading"
                        type="primary"
                        icon="el-icon-house"
                        class="w-full mt-8"
                        @click="submitForm"
                        >{{ $t('page.login.title') }}
                    </el-button>

                    <div class="mt-5 flex text-sm justify-center space-x-2">
                        <span>
                            {{ $t('page.login.dont_have_an_account') }}?
                        </span>
                        <router-link to="/register">
                            <p class="text-brand-atosa font-medium">
                                {{ $t('page.login.register_now') }}
                            </p>
                        </router-link>
                    </div>

                    <div class="flex mt-4">
                        <el-dropdown class="ml-auto" trigger="click">
                            <div>
                                <img
                                    class="w-7 h-4"
                                    :src="selected_language.image"
                                    alt=""
                                />
                            </div>

                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                    v-for="(
                                        item, index
                                    ) in p__list_select_languages"
                                    :key="index"
                                    :class="{
                                        'is-active':
                                            item.code === selected_language.code
                                    }"
                                    @click.native="handleTranslate(item.code)"
                                >
                                    <div class="flex items-center space-x-2">
                                        <img
                                            class="w-5 h-3"
                                            :src="item.image"
                                            alt=""
                                        />

                                        <p>{{ item.description }}</p>
                                    </div>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import { login } from '@/services/atosa/auth'
import i18n from '@/plugins/language'

export default {
    data() {
        return {
            selected_language: this.p__list_select_languages[0],
            rule_form: {
                email: '',
                password: ''
            },
            rules: {
                email: [
                    {
                        required: true,
                        message: this.$t('input.placeholder.please_input'),
                        trigger: 'blur'
                    }
                ],
                password: [
                    {
                        required: true,
                        message: this.$t('input.placeholder.please_input'),
                        trigger: 'change'
                    }
                ]
            },
            loading: false,
            password_type: 'password',
            redirect_url: `${window.location.href}`,
            redirect: undefined
        }
    },

    computed: {
        s__website() {
            return this.$store.getters.app.s__website
        }
    },

    watch: {
        $route: {
            handler: function (route) {
                this.redirect = route.query && route.query.redirect
            },
            immediate: true
        }
    },

    beforeMount() {
        this.rule_form.email = ''
        this.rule_form.password = ''
    },

    mounted() {
        localStorage.removeItem('user_info')
        localStorage.removeItem('token')

        const locale = this.$i18n.locale

        if (locale) {
            const temp = this.p__list_select_languages.find(
                (language) => language.code === locale
            )
            if (temp) {
                this.selected_language = temp
            }
        }
    },

    methods: {
        showPwd() {
            if (this.password_type === 'password') {
                this.password_type = ''
            } else {
                this.password_type = 'password'
            }
            this.$nextTick(() => {
                this.$refs.password.focus()
            })
        },

        submitForm() {
            this.$refs['rule_form'].validate((valid) => {
                if (valid) {
                    this.handleLogin()
                }
            })
        },

        async handleLogin() {
            this.loading = true

            try {
                const response = await login({
                    ...this.rule_form,
                    username: this.rule_form.email
                })
                if (response.data) {
                    if (response.data.token) {
                        localStorage.setItem('token', response.data.token)
                    }

                    window.location.href = this.redirect || '/'
                }
            } catch (error) {
                console.error(error)
            }

            this.loading = false
        },

        handleTranslate(code) {
            localStorage.setItem('code_lang', code)

            const url = window.location.href
            const urlParts = url.split('?')
            if (urlParts.length > 1) {
                const queryString = urlParts[1]
                const params = new URLSearchParams(queryString)

                if (params.has('lang')) {
                    params.delete('lang')
                    const newQueryString = params.toString()

                    const newURL =
                        urlParts[0] +
                        (newQueryString ? '?' + newQueryString : '')

                    window.history.replaceState({}, document.title, newURL)
                }
            }

            window.location.reload(true)
        }
    }
}
</script>

<style>
.el-carousel__container {
    height: 50px !important;
}
</style>
